<template>
    <inertia-link :href="href" :as="as" :method="method" :class="className" @click.prevent="onClick" :only="only"
        :preserve-state="preserveState">
        <JetButton type="button" class="flex items-center align-middle transition" :disabled="loading">
            <Spinner v-if="loading" :spinner-color="spinnerColor" :spinner-size="spinnerSize"> </Spinner>
            <slot></slot>
        </JetButton>
    </inertia-link>
</template>

<script>
import JetButton from "@/Components/Button.vue";
import Spinner from "./Spinner.vue"
export default {
    props: {
        href: {
            type: String,
            default: "#",
        },
        method: {
            type: String,
            default: 'get'
        },
        as: {
            type: String,
            default: 'a'
        },
        className: {
            type: String,
            default: "",
        },
        spinnerColor: {
            type: String,
            default: "blue",
        },
        spinnerSize: {
            type: Number,
            default: 4,
        },
        preserveState: {
            type: Boolean,
            default: false
        },
        only: {
            type: Array,
            default: []
        }
    },
    components: {
        JetButton,
        Spinner,
    },
    data() {
        return {
            loading: false,
        };
    },
    computed: {
        computedSpinnerColor() {
            return `fill-${this.spinnerColor}-600`;
        },
        computedSpinnerSize() {
            return `w-${this.spinnerSize} h-${this.spinnerSize}`;
        },
    },
    methods: {
        onClick() {
            this.loading = true;
            this.$emit("click");
        },
    },
};
</script>
