import moment from "moment";

const globalFilters = {
    dateFromNow(value) {
        return moment(value).fromNow();
    },
    dateIsBefore(date1, date2) {
        return moment(date1).isBefore(date2);
    },
    formatDate(value, format = "DD-MM-YYYY HH:mm") {
        if (!value) return '';
        return moment(value).format(format);
    },
    formatUnixDate(value, format = "DD-MM-YYYY HH:mm") {
        return moment.unix(value).format(format);
    },
    formatDateFromNow(value) {
        return moment(value).fromNow()
    },
    formatDateExact: function (exactDate) {
        return moment(Number(exactDate.match(/\d+/))).format("DD-MM-YYYY");
    },
    formatPrice(value) {
        let val = (value / 1).toFixed(2).replace('.', ',')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
    },
    statusCodeOrdersExact: function (value) {
        if (value == '12') {
            return 'Open'
        } else if (value == '20') {
            return 'Partial'
        } else if (value == '21') {
            return 'Complete'
        } else if (value == '45') {
            return 'Cancelled'
        }
    },
    currencyEUR(value) {
        let val = (value / 1).toFixed(2).replace('.', ',')
        return '€ ' + val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
    },
    str_limit(value, size) {
        if (!value) return '';
        value = value.toString();

        if (value.length <= size) {
            return value;
        }
        return value.replace(/<[^>]+>/g, '').substr(0, size) + '...';
    },
    humanFileSize(bytes, si = true, dp = 1) {
        const thresh = si ? 1000 : 1024;

        if (Math.abs(bytes) < thresh) {
            return bytes + " B";
        }

        const units = si
            ? ["kB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]
            : ["KiB", "MiB", "GiB", "TiB", "PiB", "EiB", "ZiB", "YiB"];
        let u = -1;
        const r = 10 ** dp;

        do {
            bytes /= thresh;
            ++u;
        } while (
            Math.round(Math.abs(bytes) * r) / r >= thresh &&
            u < units.length - 1
        );

        return bytes.toFixed(dp) + " " + units[u];
    },
};

export { globalFilters };
