<template>
  <span>
    <jet-label :for="name" :value="label" :required="required" class="mt-2" />
    <jet-input :type="type" :id="name" :name="name" class="mt-1 block w-full" :class="{ 'border-red-500': errorMessage }"
      :placeholder="placeholder" :value="modelValue" @input="$emit('update:modelValue', $event.target.value)" />
    <jet-input-error :message="errorMessage" class="mt-2" />
  </span>

</template>

<script>
import JetInput from "@/Components/Input.vue";
import JetLabel from "@/Components/Label.vue";
import JetInputError from "@/Components/InputError.vue";

export default {
  props: {
    modelValue: String | Number,
    errorMessage: String,
    placeholder: String,
    name: String,
    label: String,
    required: {
      type: Boolean,
      default: false
    },
    type: {
      type: String,
      default: 'text'
    }

  },

  emits: ['update:modelValue'],

  components: {
    JetInputError,
    JetInput,
    JetLabel
  },
  methods: {
    focus() {
      this.$refs.input.focus()
    }
  }

}
</script>
